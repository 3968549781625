export default function ({ $auth, redirect }) {
  // If the user is a guest
  if ($auth.user.token && $auth.user.isGuest === undefined) {
    return $auth.getUserPromise().then((u) => {
      if (u.isGuest) {
        return redirect('/profile/create');
      } else {
        return true;
      }
    });
  } else if ($auth.user.token && $auth.user.isGuest) {
    return redirect('/profile/create');
  }
}
