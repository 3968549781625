class Notifications {
  constructor(WonderPush, auth, config) {
    this.WonderPush = WonderPush;
    this.$auth = auth;
    this.$config = config;
    // TODO Maybe the following doesn't need to happen right away
    const options = {
      webKey: this.$config.wonderPushKey,
      geolocation: false,
      subscriptionDialog: {
        triggers: {
          manual: true,
        },
      },
      subscriptionNative: {
        triggers: {
          manual: true,
        },
      },
    };

    if (this.$auth.user.id) {
      options.userId = this.$auth.user.id;
    }

    WonderPush.push(['init', options]);
  }

  subscribe() {
    this.WonderPush = this.WonderPush || window.WonderPush || [];
    this.WonderPush.push(['showSubscriptionDialog']);
  }

  setUser() {
    if (this.$auth.user.id) {
      this.WonderPush = this.WonderPush || window.WonderPush || [];
      this.WonderPush.push(['setUserId', this.$auth.user.id]);
    }
  }

  unsetUser() {
    this.WonderPush = this.WonderPush || window.WonderPush || [];
    this.WonderPush.push(['unsetUserId']);
  }

  showSubscriptionSwitch(el) {
    this.WonderPush = this.WonderPush || window.WonderPush || [];
    this.WonderPush.push(function () {
      window.WonderPush.showSubscriptionSwitch(el);
    });
  }

  subscribeToEvent(eventUUID) {
    this.WonderPush = this.WonderPush || window.WonderPush || [];
    // TODO this is not the most accurate - technically we should wait for user
    // to successfully subscribe to notifications before adding the tag. In practice
    // this is probably just a rare edge case
    this.subscribe();
    this.WonderPush.push(function () {
      window.WonderPush.addTag(`event-${eventUUID}`);
    });
  }

  unsubscribeFromEvent(eventUUID) {
    return new Promise((resolve) => {
      this.WonderPush = this.WonderPush || window.WonderPush || [];
      this.WonderPush.push(function () {
        window.WonderPush.removeTag(`event-${eventUUID}`).then(resolve);
      });
    });
  }

  isSubscribedToNotifications() {
    return new Promise((resolve) => {
      this.WonderPush = this.WonderPush || window.WonderPush || [];
      this.WonderPush.push(function () {
        window.WonderPush.isSubscribedToNotifications().then(resolve);
      });
    });
  }

  isSubscribedToEvent(eventUUID) {
    console.log(`Checking push notifications for ${eventUUID}`);
    const eventPromise = new Promise((resolve) => {
      this.WonderPush = this.WonderPush || window.WonderPush || [];
      this.WonderPush.push(function () {
        window.WonderPush.hasTag(`event-${eventUUID}`).then(resolve);
      });
    });
    return Promise.all([eventPromise, this.isSubscribedToNotifications()]).then(
      (values) => {
        console.log(values);
        return values.every((x) => x);
      }
    );
  }
}

export default ({ $auth, $config }, inject) => {
  window.WonderPush = window.WonderPush || [];
  const wonderNotifications = new Notifications(
    window.WonderPush,
    $auth,
    $config
  );
  inject('wonderpush', wonderNotifications);
};
