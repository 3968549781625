import Vue from 'vue';

class Notifications {
  constructor($auth, $api) {
    this.$auth = $auth;
    this.$api = $api;
    this.notifications = Vue.observable({ count: null });
  }

  async refreshNotificationsCount() {
    if (this.$auth && this.$auth.user.token) {
      try {
        const resp = await this.$api.users.getNotificationsCount();
        this.notifications.count = resp.count || 0;
      } catch (error) {
        this.notifications.count = 0;
      }
    }
    setTimeout(() => this.refreshNotificationsCount(), 1000 * 20);
  }
}

export default function ({ $auth, $api }, inject) {
  const notifications = new Notifications($auth, $api);
  // Inject to context as $notifications
  inject('notifications', notifications);
}
