import Vue from 'vue';
import Cookie from 'js-cookie';

class Auth {
  constructor(config, axios, sentry, plausible) {
    this.user = Vue.observable({
      token: this.getToken(),
      isGuest: undefined,
      isBeta: undefined,
      name: '',
      id: undefined,
    });
    this.$config = config;
    this.axios = axios;
    this.sentry = sentry;
    this.plausible = plausible;
  }

  getToken() {
    let token = localStorage.getItem('authToken');
    if (token) {
      return token;
    } else if (Cookie.get('authToken')) {
      token = Cookie.get('authToken');
      localStorage.setItem('authToken', token);
      return token;
    }
    return null;
  }

  setToken(token) {
    localStorage.setItem('authToken', token);
    this.user.token = this.getToken();
  }

  setUser(user) {
    const { isGuest, name, isBeta, email, id } = user;
    this.user.isGuest = isGuest || false;
    this.user.isBeta = isBeta || false;
    this.user.name = name || '';
    this.user.id = id || null;
    this.userPromise = Promise.resolve(user);
    const userCtx = { username: user.name };
    if (email) {
      userCtx.email = email;
    }
    this.sentry.setUser(userCtx);
  }

  getUserPromise() {
    if (!this.userPromise) {
      this.userPromise = this.axios.$get('v1/users/me/', {
        headers: { Authorization: `Token ${this.user.token}` },
      });
    }
    return this.userPromise;
  }

  async getUser() {
    const user = await this.getUserPromise();
    return user;
  }

  logout() {
    localStorage.clear();
    Cookie.remove('authToken', { domain: this.$config.cookieDomain });
    this.user.token = null;
    this.sentry.setUser(null);
  }

  async magicLogin(token) {
    try {
      const user = await this.axios.$get(`v1/magic-login/?magic=${token}`);
      this.setToken(user.token);
      this.setUser(user);
      this.plausible.trackEvent('Login', {
        props: { 'Login method': 'Magic' },
      });
      return { token: user.token };
    } catch (error) {
      const code = parseInt(error.response && error.response.status);
      if (code === 403) {
        return { error: 'Existing user' };
      } else if (code === 400) {
        return { error: 'Invalid token' };
      }

      throw error;
    }
  }

  google() {
    return new Promise((resolve, reject) => {
      // TODO Remove event listener
      const popup = window.open(
        `${this.$config.apiBaseURL}/all-accounts/google/login`,
        'Google Login',
        'popup=yes,width=500,height=600,left=50,top=50'
      );
      window.addEventListener('message', (ev) => {
        popup.close();
        const data = JSON.parse(ev.data);
        if (data.loggedIn) {
          this.setToken(data.token);
          this.getUser().then((u) => this.setUser(u));
          resolve(data.token);
        } else {
          reject(Error('Authentication was unsuccessful'));
        }
      });
    });
  }

  async facebook(loginResponse) {
    if (loginResponse.status === 'connected') {
      // Logged into your webpage and Facebook.
      try {
        const { accessToken } = loginResponse.authResponse;
        const payload = {
          access_token: accessToken,
        };
        const user = await this.axios.$post('/accounts/facebook/', payload);
        this.setToken(user.token);
        this.setUser(user);
        return { token: user.token };
      } catch (error) {
        const code = parseInt(error.response && error.response.status);
        if (code === 403) {
          return { error: 'Existing user' };
        } else if (code === 400) {
          return { error: 'Invalid token' };
        }

        throw error;
      }
    } else {
      // The person is not logged into your webpage or we are unable to tell.
    }
  }
}

export default function ({ $axios, $config, $sentry, $plausible }, inject) {
  const auth = new Auth($config, $axios, $sentry, $plausible);
  if (auth.user.token) {
    auth.getUser().then((u) => auth.setUser(u));
  }
  // Inject to context as $auth
  inject('auth', auth);
}
