class Users {
  constructor(axios) {
    this.axios = axios;
  }

  async me() {
    return await this.axios.$get(`/v1/users/me/`);
  }

  async update(user) {
    return await this.axios.$put(`/v1/users/${user.id}/`, user);
  }

  async requestAccount(user) {
    return await this.axios.$post(
      `/v1/users/${user.id}/request_account/`,
      user
    );
  }

  async requestAccess(message) {
    return await this.axios.$post(`/v1/users/request_access/`, { message });
  }

  async confirmAccount(token) {
    return await this.axios.$post(`/v1/users/confirm_account/`, {
      token,
    });
  }

  async passwordChange(passwords) {
    return await this.axios.$post(`/accounts/password/change/`, passwords);
  }

  async sendMagicLink(email) {
    return await this.axios.$post(`/v1/users/magic_link/`, { email });
  }

  async getNotificationsCount() {
    return await this.axios.$get('/v1/notifications/unread/', {
      progress: false,
    });
  }

  async getNotifications(params = {}) {
    return await this.axios.$get('/v1/notifications/', { params });
  }
}

export default Users;
