class Events {
  constructor(axios) {
    this.axios = axios;
  }

  async getAll(params = {}) {
    return await this.axios.$get('/v1/events/', { params });
  }

  async get(uuid) {
    return await this.axios.$get(`/v1/events/${uuid}/`);
  }

  async getPermissions(uuid) {
    return await this.axios.$get(`/v1/events/${uuid}/permissions/`);
  }

  async getBggGame(bggId) {
    return await this.axios.$get(`/v1/games/bgg/${bggId}/`);
  }

  async create(data) {
    return await this.axios.$post('/v1/events/', data);
  }

  async update(uuid, data) {
    return await this.axios.$put(`/v1/events/${uuid}/`, data);
  }

  async submitComment(uuid, comment) {
    return await this.axios.$post(`/v1/events/${uuid}/comment/`, comment);
  }

  async vote(uuid, vote) {
    return await this.axios.$post(`/v1/events/${uuid}/vote/`, vote);
  }

  async updatePoll(uuid, poll) {
    return await this.axios.$put(`/v1/events/${uuid}/poll/`, poll);
  }

  async sendEventMagicLink(uuid, guest) {
    return await this.axios.$post(`/v1/events/${uuid}/magic/`, guest);
  }

  async claimGuest(uuid, guest) {
    return await this.axios.$post(`/v1/events/${uuid}/claim_guest/`, guest);
  }

  async selectPollOptions(uuid, option) {
    return await this.axios.$post(
      `/v1/events/${uuid}/select_final_poll_options/`,
      option
    );
  }

  async suggestGame(uuid, games) {
    return await this.axios.$post(`/v1/events/${uuid}/suggest_game/`, games);
  }

  async rsvp(uuid, resp) {
    return await this.axios.$post(`/v1/events/${uuid}/rsvp/`, resp);
  }

  async rsvpComment(uuid, comment) {
    return await this.axios.$post(`/v1/events/${uuid}/rsvp_comment/`, comment);
  }

  async enableNotifications(uuid, notificationsEnabled) {
    return await this.axios.$post(`/v1/events/${uuid}/notifications/`, {
      notificationsEnabled,
    });
  }

  async disableNotifications(uuid) {
    return await this.axios.$post(`/v1/events/${uuid}/notifications/`, {
      notificationsEnabled: null,
    });
  }

  async ownerNotifications(uuid, notifications) {
    return await this.axios.$post(
      `/v1/events/${uuid}/owner_notifications/`,
      notifications
    );
  }

  async inviteGuests(uuid, guests) {
    return await this.axios.$post(`/v1/events/${uuid}/invite_guests/`, guests);
  }

  async previousGuests() {
    return await this.axios.$get('/v1/events/previous_guests/');
  }

  async getCustomEmail(uuid) {
    return await this.axios.$get(`/v1/events/${uuid}/custom_email/`);
  }

  async updateCustomEmail(uuid, customEmail) {
    return await this.axios.$put(`/v1/events/${uuid}/custom_email/`, {
      customEmail,
    });
  }

  async changeAttendance(uuid, id, status) {
    return await this.axios.$put(`/v1/events/${uuid}/change_attendance/`, {
      id,
      status,
    });
  }
}

export default Events;
