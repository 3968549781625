import Events from './services/events';
import Users from './services/users';

export default function ({ $axios, $auth }, inject) {
  const axios = $axios.create();
  axios.onError((error) => {
    const code = parseInt(error.response && error.response.status);
    // if (code === 403) {
    //   //   TODO maybe redirecting is not the right way to do it
    //   redirect('/login');
    // }
    if (code === 400) {
      if (error.response.data && error.response.data.error) {
        error.message = error.response.data.error;
        error.human = !!error.response.data.human;
      } else if (error.response.data) {
        let message = '';
        for (const err of Object.keys(error.response.data)) {
          message = `${message}\n${err}: ${error.response.data[err]}`;
        }
        error.message = message;
      }
    }
    if (code === 500) {
      error.message =
        "Something went wrong. We've been notified of this error, but feel free to try again or reach out.";
    }
  });
  const events = new Events(axios);
  const users = new Users(axios);

  const resetToken = () => {
    axios.setToken($auth.user.token, 'Token');
  };

  // TODO Not sure if this is the correct approach
  if ($auth.user.token) {
    resetToken();
  }

  const api = { events, users, axios, resetToken };

  // Inject to context as $api
  inject('api', api);
}
