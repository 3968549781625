
export default {
  data: () => {
    return {
      longPage: false,
      editingView: false,
    };
  },
  head() {
    const url = `${this.$config.baseURL}${this.$route.path}`;
    return {
      meta: [
        {
          hid: 'og:url',
          name: 'og:url',
          content: url,
        },
        {
          hid: 'twitter:url',
          name: 'twitter:url',
          content: url,
        },
        {
          hid: 'image',
          name: 'image',
          content: `${this.$config.apiBaseURL}/image.png`,
        },
        {
          hid: 'og:image',
          name: 'og:image',
          content: `${this.$config.apiBaseURL}/image.png`,
        },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          content: `${this.$config.apiBaseURL}/image.png`,
        },
      ],
    };
  },
  computed: {
    isAuthenticated() {
      return !!this.$auth.user.token;
    },
    isGuest() {
      return this.$auth.user.isGuest;
    },
    name() {
      return this.$auth.user.name;
    },
    notificationsCount() {
      return this.$notifications.notifications.count;
    },
  },
  mounted() {
    this.$notifications.refreshNotificationsCount();
  },
  methods: {
    logout() {
      this.$auth.logout();
      this.$wonderpush.unsetUser();
      this.$router.push('/login');
    },
    onSetLongPage(ev) {
      this.longPage = ev;
    },
    onSetEditingView(ev) {
      this.editingView = ev;
    },
  },
};
